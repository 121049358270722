// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ProjectCube from "./project-cube"

const Solutions = () => (
	<div className="scroll-jacker__cassette pp-scrollable" style={{ backgroundColor: "rgba(35, 35, 35, 0.96)" }}>
        <ul className="gallery social-feed-list">
        
            

            <ProjectCube
                title="La Caisse enregistreuse sur iPad"
                action="/projets/precision-systems"
                image="/img/posts/precisionsystems.jpg"
            />
            
        </ul>

    </div>
)

export default Solutions
